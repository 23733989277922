<div class="footer-top" data-rr-shared="true">
  <div class="text-center d-flex container justify-content-between flex-wrap">
    <div class="my-2">
      <img
        ngSrc="https://a.storyblok.com/f/106593/1003x605/522bc04b70/dankort-logo.png"
        alt="Supervin betalingskort" class="cards-img" height="29" width="157">
      <img
        ngSrc='https://a.storyblok.com/f/106593/243x153/5908f5a59e/visa.svg'
        alt="Supervin betalingskort" class="cards-img" height="29" width="157">
      <img
        ngSrc='https://a.storyblok.com/f/106593/243x153/ca211d35bd/mastercard.svg'
        alt="Supervin betalingskort" class="cards-img cards-background" height="29" width="157">
      <img
        ngSrc='https://a.storyblok.com/f/106593/243x153/79fe8b0487/maestro.svg'
        alt="Supervin betalingskort" class="cards-img cards-background" height="29" width="157">
    </div>
    <a class="px-4 my-2" href="https://certifikat.emaerket.dk/supervin.dk" target="_blank">
      <img class="trust-logo"
           height="64" ngSrc="https://a.storyblok.com/f/106593/250x150/185962dc36/e-maerket.png" width="64">
    </a>
  </div>
</div>

<div class="footer-middle" data-rr-shared="true">
  <div class="container row">
    <div class="footer-col col-sm-12 col-md-6 col-lg-4">
      <nav class="block-menu-menu-footer-top-menu block block-menu"
           role="navigation">
        <a [appRouterContext]="'footer'" [routerLink]="'/info/hvem-er-vi'">Hvem er vi?</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/aabningstider'">Åbningstider</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/betingelser'">Handelsbetingelser</a>
        <a href="/info/betingelser#Withdrawal">Fortrydelsesret</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/cookiepolitik'">Cookiepolitik</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/persondatapolitik'">Persondatapolitik</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/leveringsinformation'">Leveringsinformation</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/kontakt-os'">Kontakt</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/engros-salg'">Engros salg</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/b2b-salg'">B2B Salg</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/topkategorier'">Topkategorier</a>
        <a href="https://blog.supervin.dk/" target="_blank">Blog</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/info/konkurrence'">Konkurrencer</a>
        <a [appRouterContext]="'footer'" [routerLink]="'/black-friday'">Black Friday 2025</a>
      </nav>
    </div>
    <div class="footer-col col-sm-12 col-md-6 col-lg-4 col2">
      <div class="row">
        <div class="col-12 footer-icon">
          <i class="supervin-icon-store-white"></i> <span>Jyllands største vinbutik</span>
        </div>
        <div class="col-12 footer-icon"><i class="supervin-icon-truck-white"></i> <span>Fragt fra 19,- | Fri fragt 1.000,-</span>
        </div>
        <a class="col-12 col-lg-6 footer-icon" href="https://www.facebook.com/SuperVin.dk" target="_blank"> <i
          class="supervin-icon-facebook-white-round"></i> <span>Besøg os på facebook</span> </a>
        <div class="col-12 col-lg-6 footer-icon">
          <i class="supervin-icon-chat"></i>
          <span><span>Kundeservice: </span><br><span> 98 92 18 53</span>
            </span>
        </div>
      </div>
    </div>
    <div class="footer-col col-sm-12 col-lg-4">
      <section class="block block-supervin-hubspot clearfix ">
        <div>
          <p class="title">
            <span>Tilmeld vores nyhedsbrev</span>
            <br>
            <span class="sub-text">- og modtag de bedste tilbud</span>
          </p>
          <a class="button-primary" (click)="newsletterSignup()">Tilmeld</a>
          <span class="info-icon">
                <a (click)="memberConditions()">
                  <img ngSrc='https://a.storyblok.com/f/106593/24x24/b15616d754/information.png' height="24"
                       width="24">
                </a>
              </span>
        </div>
      </section>
    </div>
  </div>
</div>
<div class="footer-bottom" data-rr-shared="true">
  <div class="container">
    <p class="footer-text">SUPERVIN, HJØRRING A/S</p>
    <p class="footer-text">Skagensvej 201</p>
    <p class="footer-text">9800 Hjørring</p>
    <p class="footer-text"> Tlf. 98921853</p>
    <p class="footer-text"><span>CVR 21281573</span>&nbsp; <a
      href="mailto:info@supervin.dk">info&#64;supervin.dk</a></p>
    <a href="https://www.findsmiley.dk/23815" target="_blank">
          <span class="footer-text">
            <img ngSrc='https://a.storyblok.com/f/106593/99x32/307796f795/banner_gul_99x32.gif' height="32" style="width: 99px; height: 32px;"
                 width="99">
          </span>
    </a>
  </div>
</div>
