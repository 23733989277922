import {Component, effect, EventEmitter, HostBinding, HostListener, input, Output, signal,} from '@angular/core';
import {CartService} from '@core/services/cart.service';
import {ProductBase, WineProduct} from '@core/interfaces/generated/graphql';
import {GoogleService} from '@core/services/google.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {FormsModule} from '@angular/forms';
import {ProductLikeComponent} from '@layout/components/product/product-like/product-like.component';
import {QtyInputComponent} from '@layout/components/product/qty-input/qty-input.component';

@Component({
  selector: 'form[add-to-cart]',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, FormsModule, ProductLikeComponent, QtyInputComponent]
})
export class AddToCartComponent {

  addingToCart = signal(false);

  product = input<WineProduct | ProductBase | undefined>(undefined);
  eventSourceName = input.required<string>();
  hitPosition = input<number>();
  hideLikeButton = input<boolean>(false);

  @Output() addedToCart: EventEmitter<boolean> = new EventEmitter<boolean>();

  qtyInput = signal(1);

  @HostBinding('class') class = 'no-gutters';

  constructor(
    private cartService: CartService,
    private googleService: GoogleService
  ) {
    effect(() => {
      this.qtyInput.set(this.product()?.defaultQuantity ?? 1);
    }, {allowSignalWrites: true});
  }

  @HostListener('submit', ['$event'])
  formSubmit(event: any) {
    event.preventDefault();
    this.addToCart();
  }

  addToCart(event?: MouseEvent) {
    const product = this.product();
    event?.preventDefault();
    const productId = parseInt(<string>product?.productId, 10);
    const qty = this.qtyInput();
    const vismaId = (product as any)?.vismaProductNumber?.toString() ?? '';
    if (!product || !productId || !qty) {
      console.warn('addToCart: product, productId or qty is missing', {product, productId, qty})
      // This should never happen, so it's important we through an error that will be caught by Sentry
      throw new Error('addToCart: product, productId or qty is missing for product id: #' + product?.id);
    }
    const isPreorder = !!product?.preorderDate;
    const isEnPrimeur = !!(product as WineProduct)?.isEnPrimeur;

    this.cartService.addToCart(vismaId, productId as any, qty as any, this.eventSourceName(), undefined, this.hitPosition(), isPreorder, isEnPrimeur)
      .then(() => {
        this.googleService.addProductToCartEvent(product, qty);
        this.addedToCart.emit(true);
      })
      .finally(() => {
        this.addingToCart.set(false)
      })
      .catch(error => {
        this.addedToCart.emit(false);
        throw error;
      })
    this.addingToCart.set(true)
  }

  setQtyInput(qty: number) {
    this.qtyInput.set(qty);
  }

  protected readonly faAngleUp = faAngleUp;
  protected readonly faAngleDown = faAngleDown;

  getProduct(): WineProduct | null {
    const product = this.product();

    if (product === undefined) {
      return null;
    }

    return product as WineProduct;
  }
}
