import {Component, effect, EventEmitter, HostBinding, input, OnDestroy, OnInit, Output, signal} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-qty-input',
  templateUrl: './qty-input.component.html',
  styleUrls: ['./qty-input.component.scss'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    FormsModule
  ]
})
export class QtyInputComponent implements OnInit, OnDestroy {

  qty = input<number>(1);

  disable = input<boolean>(false);
  small = input<boolean>(false);
  enableDebounce = input<boolean>(false);

  qtyField = signal(1);

  @Output() qtyChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() submitEvent: EventEmitter<number> = new EventEmitter<number>();
  updateQtyEmitter: Subject<number> = new Subject<number>();
  updateQtySub: Subscription;

  constructor() {
    effect(() => {
      this.qtyField.set(this.qty());
    }, {allowSignalWrites: true});
  }

  @HostBinding('class.small') get smallClass() {
    return this.small();
  }

  @HostBinding('class.disable') get disableClass() {
    return this.disable();
  }

  ngOnInit() {
    this.updateQtySub = this.updateQtyEmitter.pipe(
      debounceTime(this.enableDebounce() ? 800 : 0),
    ).subscribe(qty => {
      this.qtyChange.emit(qty);
    });
  }

  ngOnDestroy() {
    this.updateQtySub.unsubscribe();
  }

  onEnter(event: any) {
    event.preventDefault();
    event.target.blur();
    this.submitEvent.emit(this.qtyField());
  }

  minusProduct() {
    this.qtyField.update(qty => {
      if (qty > 1) {
        return qty - 1;
      }
      return qty;
    })

    this.updateQtyEmitter.next(this.qtyField());
  }

  plusProduct() {
    this.qtyField.update(qty => qty + 1);

    this.updateQtyEmitter.next(this.qtyField());
  }

  setQtyField(value: number) {
    this.qtyField.set(value);
  }

  onInputBlur($event: any) {
    let value = $event.target.value;
    if (value === '') {
      value = 0;
    }
    const qty = parseInt(value, 10);
    this.qtyChange.emit(qty);
  }

  protected readonly faMinus = faMinus;
  protected readonly faPlus = faPlus;
}
