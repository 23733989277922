import {Component, HostBinding, input} from '@angular/core';
import {WineProduct} from '@core/interfaces/generated/graphql';


@Component({
  selector: 'app-product-save-label',
  templateUrl: './product-save-label.component.html',
  styleUrls: ['./product-save-label.component.scss'],
  standalone: true,
  imports: []
})
export class ProductSaveLabelComponent {

  product = input.required<WineProduct | undefined>();

  @HostBinding('class.yellow-badge') get isYellowBadge() {
    return this.product()?.productImageBadge?.type === 'show_discount';
  }

  constructor(
  ) {
  }
}
