const AND_SPLITTER = ') AND (';

export function getAlgoliaReviewersFromFilter(filters: string): string[] {
  const reviewers: string[] = [];
  const filtersWithoutStartAndEndBracket = getFilterWithoutStartAndEndBracket(filters);
  let filterAndGroups = filtersWithoutStartAndEndBracket?.split(AND_SPLITTER) ?? [];

  filterAndGroups.forEach((filter, index) => {
    const filterOrGroup = filter.split(' OR ');
    filterOrGroup.forEach(item => {
      if (item.indexOf('ratings.') !== -1) {
        const reviewerName = item.split(':')[0].replace(/'/g, '');
        reviewers.push(reviewerName.replace('ratings.', ''));
      }
    });
  });
  return reviewers;
}

export function updateFilterWithReviewers(filters: string, reviewers: string[], range: { min: number, max: number }) {
  if (filters) {
    filters = removeAllReviewersFromFilter(filters);
  }

  const filtersWithoutStartAndEndBracket = getFilterWithoutStartAndEndBracket(filters);
  let filterAndGroups = filtersWithoutStartAndEndBracket?.split(AND_SPLITTER) ?? [];

  const reviewerFilterArray = [];
  for (const reviewer of reviewers) {
    reviewerFilterArray.push(`'ratings.${reviewer}':${range.min} TO ${range.max}`);
  }

  if (reviewerFilterArray.length > 0) {
    filterAndGroups.push(`${reviewerFilterArray.join(' OR ')}`);
  }

  const filter = filterAndGroups.filter(item => item).join(AND_SPLITTER);
  return filter ? `(${filter})` : '';
}

export function removeReviewerFromFilter(filters: string, reviewer: string) {
  const filtersWithoutStartAndEndBracket = getFilterWithoutStartAndEndBracket(filters);
  const filterAndGroups = filtersWithoutStartAndEndBracket?.split(AND_SPLITTER) ?? [];

  filterAndGroups.map((filter, index) => {
    const filterOrGroup = filter.split(' OR ');
    const newFilterOrGroup = filterOrGroup.filter(item => item.indexOf(`'ratings.${reviewer}'`) === -1);
    filterAndGroups[index] = newFilterOrGroup.join(' OR ');
  });

  const filter = filterAndGroups.filter(item => item).join(AND_SPLITTER);
  return filter ? `(${filter})` : '';
}

export function removeAllReviewersFromFilter(filters: string) {
  const filtersWithoutStartAndEndBracket = getFilterWithoutStartAndEndBracket(filters);
  const filterAndGroups = filtersWithoutStartAndEndBracket?.split(AND_SPLITTER) ?? [];

  filterAndGroups.map((filter, index) => {
    const filterOrGroup = filter.split(' OR ');
    const newFilterOrGroup = filterOrGroup.filter(item => item.indexOf('ratings.') === -1);
    filterAndGroups[index] = newFilterOrGroup.join(' OR ');
  });

  const filter = filterAndGroups.filter(item => item).join(AND_SPLITTER);
  return filter ? `(${filter})` : '';
}

export function getFilterWithoutStartAndEndBracket(filters: string): string {
  if (filters.startsWith('(') && filters.endsWith(')')) {
    return filters.substring(1, filters.length - 1);
  }
  return filters;
}
