<div class="input-group">
  <button (click)="minusProduct()" class="btn btn-minus" type="button">
    <fa-icon [icon]="faMinus"></fa-icon>
  </button>
  <input (blur)="onInputBlur($event)" (keydown.enter)="onEnter($event)" (ngModelChange)="setQtyField($event)"
         [ngModel]="qtyField()" class="form-control input-qty"
         type="number">
  <button (click)="plusProduct()" class="btn btn-plus" type="button">
    <fa-icon [icon]="faPlus"></fa-icon>
  </button>
</div>
